  <template>
    <div class="bg-gray-100 dark:bg-gray-800 min-h-screen ">
      <div class="max-w-4xl mx-auto p-6 bg-white dark:bg-gray-900 rounded-md shadow-md ">
        <h2 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Leave a Message</h2>
        <form @submit.prevent="submitForm">
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-200 mb-2" for="name">Name</label>
            <input v-model="form.name" type="text" id="name" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-200 mb-2" for="email">Email</label>
            <input v-model="form.email" type="email" id="email" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-200 mb-2" for="message">Message</label>
            <textarea v-model="form.message" id="message" rows="4" required class="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-white"></textarea>
          </div>
          <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">Send Message</button>
        </form>
        <p v-if="status" class="mt-4 text-center" :class="statusClass">{{ status }}</p>
      </div>

      <!-- Social Section -->
      <div class="max-w-4xl mx-auto p-6 bg-white dark:bg-gray-900 rounded-md shadow-md mt-4">
        <h2 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Other means</h2>
        <div class="flex justify-center space-x-4">
          <a href="https://www.youtube.com/@BigJovic-lb1dj" target="_blank" class="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-500">
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M19.615 5.183c-.613-.524-1.27-.626-2.003-.661C15.288 4.316 12 4.316 12 4.316s-3.289 0-5.612.207c-.732.035-1.389.138-2.002.661-.614.525-1.033 1.247-1.197 2.288-.296 1.891-.296 5.858-.296 5.858s0 3.967.296 5.857c.164 1.042.583 1.764 1.197 2.289.613.524 1.27.626 2.002.66 2.323.208 5.612.208 5.612.208s3.288 0 5.611-.207c.732-.035 1.389-.137 2.002-.66.614-.525 1.033-1.247 1.197-2.289.296-1.89.296-5.857.296-5.857s0-3.967-.296-5.858c-.164-1.041-.583-1.763-1.197-2.288zm-11.81 9.504V9.313l5.413 2.687-5.413 2.687z" />
            </svg>
          </a>
          <a href="mailto:dearjovic@gmail.com" class="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-500">
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 12.713L1.805 6.24A1.992 1.992 0 011.995 6c.227 0 .452.06.664.179l8.805 4.9 8.806-4.9a2 2 0 112.665 1.822L12 12.713zm.165 1.086L23 7.788v9.719a1.991 1.991 0 01-1.995 2H2.995A1.991 1.991 0 011 17.507V7.788l10.835 6.011a.997.997 0 001.33-.25z" />
            </svg>
          </a>
          <a href="https://wa.me/+256776548436" target="_blank" class="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-500">
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12.012 1.965c-5.524 0-10 4.48-10 10 0 1.778.465 3.45 1.351 4.957l-1.174 4.444a.783.783 0 00.764.96.787.787 0 00.425-.13l4.37-1.71a9.891 9.891 0 004.264 1.013c5.523 0 10-4.48 10-10s-4.477-10-10-10zm4.838 14.771a8.397 8.397 0 01-3.668.859c-1.642 0-3.226-.485-4.582-1.404l-3.119 1.221 1.216-3.05c-.875-1.366-1.337-2.94-1.337-4.544 0-4.536 3.699-8.23 8.233-8.23 4.535 0 8.232 3.694 8.232 8.23 0 2.208-.855 4.28-2.403 5.849-.14.144-.313.226-.503.226-.18 0-.35-.072-.477-.194a.689.689 0 01-.186-.59 8.163 8.163 0 00.317-1.374c-.148.053-.297.102-.446.145a.755.755 0 01-.764-.196.76.76 0 01-.196-.755c.128-.482.192-.983.192-1.484 0-.183-.014-.368-.042-.553a.749.749 0 01-.128-.406c-.05-.236-.125-.468-.223-.695a.748.748 0 01-.096-.473.76.76 0 01.264-.418c.148-.148.282-.311.398-.485.115-.172.209-.36.27-.558a.747.747 0 01.31-.423.749.749 0 01.486-.064 7.762 7.762 0 001.668 1.134.74.74 0 01.361.964c-.118.372-.305.728-.556 1.045-.177.227-.363.453-.553.67-.19.217-.414.433-.643.635a6.146 6.146 0 00-.79.824c-.177.229-.293.494-.345.772-.051.276-.033.558.053.829.093.297.263.565.496.764a8.289 8.289 0 004.246 1.162c.623 0 1.24-.08 1.848-.235a8.403 8.403 0 002.82-1.534.748.748 0 00.054-.927c-.172-.286-.345-.572-.517-.857z" />
            </svg>
          </a>
          <a href="https://x.com/chronixxjam" target="_blank" class="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-500">
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M23.953 4.569c-.885.39-1.83.654-2.825.775a4.942 4.942 0 002.163-2.725 9.837 9.837 0 01-3.128 1.197 4.932 4.932 0 00-8.482 4.5 13.985 13.985 0 01-10.148-5.148 4.92 4.92 0 001.524 6.573 4.924 4.924 0 01-2.228-.616v.06a4.935 4.935 0 003.946 4.827 4.933 4.933 0 01-2.224.084 4.935 4.935 0 004.604 3.42 9.881 9.881 0 01-7.307 2.034 13.942 13.942 0 007.548 2.211c9.056 0 14.002-7.506 14.002-14.002 0-.213-.005-.425-.014-.636a9.935 9.935 0 002.451-2.549z" />
            </svg>
          </a>
        </div>
        <audio id="background-music" :src="musicSrc" loop></audio>
      </div>
      
    </div>
  </template>

  <script setup>
  import { ref } from 'vue';
  import { collection, addDoc } from 'firebase/firestore';
  import { db } from '../firebase';
  import { useStore } from 'vuex';

  const form = ref({
    name: '',
    email: '',
    message: ''
  });

  const status = ref('');
  const statusClass = ref('');
  const store = useStore();

  const submitForm = async () => {
    // try {
    //   await addDoc(collection(db, 'messages'), {
    //     name: form.value.name,
    //     email: form.value.email,
    //     message: form.value.message,
    //     timestamp: new Date()
    //   });
    //   form.value.name = '';
    //   form.value.email = '';
    //   form.value.message = '';
    //   status.value = 'Message sent successfully!';
    //   statusClass.value = 'text-green-500';
    // } catch (e) {
    //   console.error('Error adding document: ', e);
    //   status.value = 'Error sending message. Please try again.';
    //   statusClass.value = 'text-red-500';
    // }


  const WEB3FORMS_ACCESS_KEY =  "517249ed-2a9a-4009-9beb-e368f7f81608"

    const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: WEB3FORMS_ACCESS_KEY,
            name: form.value.name,
            email: form.value.email,
            message: form.value.message,
          }),
        });
        const result = await response.json();
        if (result.success) {
          status.value = 'Message sent successfully!';
          statusClass.value = 'text-green-500';
          
          // reseting form
            // Reset form fields
        form.value.name = '';
        form.value.email = '';
        form.value.message = '';
        }
  };
  </script>

  <style scoped>
 
 body {
    font-family: 'Playwrite ES Deco', sans-serif;
}
.text-gray-900 {
  font-family: 'Playwrite ES Deco', sans-serif;
}
  label {
    font-weight: 600;
  }

  input, textarea {
    transition: border-color 0.3s ease;
  }

  input:focus, textarea:focus {
    border-color: #3b82f6;
  }

  button {
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #2563eb;
  }

  @media (max-width: 640px) {
    .max-w-4xl {
      max-width: 90%;
    }
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    .max-w-4xl {
      max-width: 85%;
    }
  }

  @media (min-width: 1025px) {
    .max-w-4xl {
      max-width: 75%;
    }
  }
  </style>
